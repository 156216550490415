<template>
    <div>
        <section class="wpo-contact-pg-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col col-lg-10 offset-lg-1">
                        <div class="wpo-contact-title">
                            <h2>¿Te gustraria asistrir a mi Evento?</h2>
                            <p>Por favor confirma tu asistencia</p>
                        </div>
                        <div class="wpo-contact-form-area">
                            <form method="post" class="contact-validation-active" id="contact-form-main">
                                <div style="margin-bottom: 0px;">
                                    <b-form-group
                                      label="Nombre"
                                      label-for="Nombre"
                                    >
                                     <b-form-input
                                       v-model="NameGuest"
                                       type="text"
                                       class="form-control-merge"
                                       placeholder="Nombre"
                                     />
                                    </b-form-group>
                                </div>
                                <div style="margin-bottom: 0px;">
                                    <b-form-group
                                      label="Apellido"
                                      label-for="Apellido"
                                    >
                                     <b-form-input
                                       v-model="LastNameGuest"
                                       type="text"
                                       class="form-control-merge"
                                       placeholder="Apellido"
                                     />
                                    </b-form-group>
                                </div>
                                <b-row class="fullwidth" style="margin-left: 5px; margin-right: 5px;">
                                    <b-col md="2">
                                        <b-form-group
                                          label="¿Asisitiras?"
                                          label-for="¿Asisitiras?"
                                        >
                                            <b-form-radio-group
                                              id="radio-group-1"
                                              v-model="ConfirmationSelected"
                                              :options="optionsConfirmation"
                                              name="radio-options"
                                            ></b-form-radio-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="5">
                                        <b-form-group
                                          label="Telefono"
                                          label-for="Telefono"
                                        >
                                         <b-form-input
                                           v-model="TelephoneGuest"
                                           type="text"
                                           class="form-control-merge"
                                           placeholder="Telefono"
                                         />
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="5">
                                        <b-form-group
                                          label="Numero de Acompañantes"
                                          label-for="Numero de Acompañantes"
                                        >
                                            <b-form-spinbutton id="demo-sb" size="lg" v-model="EscortGuest" min="1" max="4"></b-form-spinbutton>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <div class="fullwidth">
                                    <b-form-group
                                      label="Notas para tu Festejado"
                                      label-for="Notas para tu Festejado"
                                    >
                                        <textarea v-model="Message" class="form-control" name="note"  id="note" placeholder="Message..."></textarea>
                                    </b-form-group>
                                </div>
                                <b-button @click="SendInvitation()">
                                    enviar
                                </b-button>
                            </form>
                        </div>
                    </div>                
                </div>
            </div> <!-- end container -->
        </section>

    </div>
</template>

<script>
import { CREATE_GUEST_BY_THE_INVITATION } from "@/core/services/store/invitation.module";
export default {
  data() {
    return {
        NameGuest: '',
        LastNameGuest: '',
        ConfirmationSelected: '1',
        optionsConfirmation: [
          { text: 'Si', value: '1' },
          { text: 'No', value: '0' },
        ],
        TelephoneGuest: '',
        EscortGuest: '',
        Message: '',
        SlugName: '',
    };
  },
  created() {
    this.SlugName = this.$route.name
    this.getInvitationInfo()
  },
  methods: {
    getInvitationInfo() {
      const InvitationParams = this.$store.getters['getInvitationParams']
      this.InvitationIds = InvitationParams
    },
    SendInvitation(){
        this.$store.dispatch(CREATE_GUEST_BY_THE_INVITATION, {
          firstName: this.NameGuest,
          lastName: this.LastNameGuest,
          phone: this.TelephoneGuest,
          assistance: this.ConfirmationSelected,
          escorts: this.EscortGuest,
          notes: this.Message,
          Slug: this.SlugName, // this.SlugName
        })
        .then((response) => {
          console.log(response);
        })
    }
  }
}
</script>

<style>

</style>